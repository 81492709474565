main {
    height: 100vh;
    background-image: url('https://i.ibb.co/TqDmfMT/ski-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.transparent-bg {
    background: rgba(62, 212, 176, 0.6);
    background-size: cover;
    min-height: 100%;
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    font-family: 'Barlow', sans-serif;
}

.contact {
    display: flex;
    justify-content: space-around;
}

.icon {
    font-size: 2.5rem;
    color: black;
    margin-right: 5px;
}

.icon:hover {
    color: white;
}


.header {
    font-weight: 900;
    font-size: 4em;
    color: black;
    text-align: center;
}

.intro {
    color: rgba(62, 212, 176);
    background-color: black;
    width: 400px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bullets {
    display: flex;
}

.icon-light-big {
    font-size: 4.1rem;
    color: white;
    margin-right: 5px;
}

.icon-light-big:hover {
    color: rgba(62, 212, 176);
}

.contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    margin: auto;
}

.btn-CV:hover {
    background-color: rgba(62, 212, 176);
    border-color: rgba(62, 212, 176);
}

.codeclan-link:hover {
    background-color: white;
    color: black;
}

.profile {
    float: right;
    width: 300px;
    border-radius: 50%;
    border: 5px solid rgba(62, 212, 176);
}

.about-container {
    font-size: 1.2rem;
    background-color: black;
    color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.transparent-bg-about {
    background: rgba(62, 212, 176, 0.6);
    background-size: cover;
    min-height: 100%;
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    font-family: 'Barlow', sans-serif;
}

@media only screen and (max-width: 600px) {
    .heading {
        text-align: center;
    }

    .about-container {
        width: 95%;
    }

    .profile {
        float: none;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
}