.project-container {
    font-size: 1.2rem;
    background-color: black;
    color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.heading {
    color: rgba(62, 212, 176);
}

.btn {
    font-size: 2rem;
    margin-right: 15px;
}

.btn-container {
    display: flex;
    justify-content: center;
}

a {
    color: white;
}

@media only screen and (max-width: 600px) {
    .project-container {
        width: 95%;
    }
}